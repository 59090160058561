<template>
  <div>
    <div class="page-banner">
      <div
        class="container"
        style="
            background-image: url('https://downloads.acs.com.hk/page-headers/2017-corporate-profile.jpg?5fc8a9b6a0943');
          "
      >
        <div class="wrapper">
          <div class="content">
            <h1>
              <a class="title">Company Vision</a>
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container corporate-profile">
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <div
            class="page-content"
            itemscope
            itemtype="http://schema.org/WebPage"
          >
            <div id="corporate-profile-content">
              <p>
                To provide the most comprehensive and effective business
                solutions for Malaysian’s SME
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyVision"
};
</script>
